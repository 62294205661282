import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'asap-crud';

@Component({
  selector: 'app-landpage',
  templateUrl: './landpage.component.html',
  styleUrls: ['./landpage.component.scss']
})

export class LandpageComponent {

    @Input() message = "não deve ser utilizada diretamente...";
    @Input() loading = false;


    constructor(
        public router: Router,
        public global: Global
    ) {

    }


}
