import { AsapBaseService } from 'asap-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService extends AsapBaseService {

    public params:any = null;

    constructor(public http: HttpClient) {
        super(http);
        this.basePath = 'api';
        this.entityName = 'v2'
    }

    public getUserLogged() {
        return this.getResources({router: 'client/user/authenticated'});
    }

    public verify() {
        return this.getResources({router: 'client/verifica'});
    }

    public theme(id) {
        return this.getResources({router: 'admin/color/' + id});
    }

    public setSessionToken(data): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            localStorage.setItem('plainToken', data.token);
            const token = 'Bearer' + ' ' + data.token;
            localStorage.setItem('token', token);
            setTimeout(() => {
                this.getUserLogged().subscribe((response: any) => {
                    localStorage.setItem('loggedUser', JSON.stringify(response));
                    setTimeout(() => {
                        resolve(true);    
                    }, 100);
                    
                    // this.verify().subscribe(verifica => {
                    //     if (response.success === false) {
                    //         resolve(false);
                    //     } else {
                    //         localStorage.setItem('loggedUser', JSON.stringify(response));
                    //         resolve(true);
                    //     }
                    // });
                }, err => reject(err));
            }, 100);
        });
    }

}