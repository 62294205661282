import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxNouisliderModule } from 'ngx-nouislider';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ForbidenComponent } from './auth-guard/forbiden.component';
import { SharedModule } from './shared.module';
import { MaterialModule } from './material.module';
import { AuthModule } from './auth.module';
import { ClientInterceptor } from './interceptors/client-interceptor';
import { LandpageComponent } from './landpage/landpage.component';
import { AsapCrudModule } from 'asap-crud';
import { AsapSharedModule } from 'asap-shared';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AsapChatModule } from 'asap-chat';
import { environment } from 'src/environments/environment';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function asapConfigFactory(http: HttpClient) {
    return () => new Promise<boolean>((resolve) => {
        const wait = () => {
            if (AuthModule.firebaseLoaded) {
                console.log('[asapConfigFactory] - Config Loaded', new Date(), AuthModule.firebaseLoaded);
                setTimeout(() => {
                    resolve(true);
                }, 500);
            } else {
                console.log('[asapConfigFactory] - Aguardando Config', new Date(), AuthModule.firebaseLoaded);
                setTimeout(wait, 1000);
            }
        };
        wait();
    });
}

if (environment.production) {
    window.console.log = () => { };
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        SimpleNotificationsModule.forRoot(),
        NgxNouisliderModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            }
        }),
        SharedModule,
        MaterialModule,
        AsapCrudModule,
        AsapSharedModule,
        AsapChatModule,
        AuthModule
    ],
    declarations: [
        AppComponent,
        ForbidenComponent,
        LandpageComponent,
        DashboardComponent
    ],
    exports: [
        SharedModule,
        MaterialModule,
        DashboardComponent
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: asapConfigFactory,
            deps: [HttpClient,AuthModule],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ClientInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
