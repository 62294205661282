import { Injectable, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, CanLoad, Route, UrlSegment } from '@angular/router';
import { AuthService } from './auth.service';
import { Global } from 'asap-crud';
import { Observable } from 'rxjs';

declare var swal: any;

@Injectable()
export class AuthGuardService implements CanLoad, CanActivate {

    constructor(
        public global: Global,
        public auth: AuthService,
        public router: Router,
        public route: ActivatedRoute
    ) {
        console.log('[AuthGuardService]',new Date())
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {

        if (this.global.loggedUser()) {
            if (['forbiden'].indexOf(route.path) >= 0) {
                this.router.navigate(['/home']);
                return false;
            }
            return true;
        } 
        else {
            console.log('[AuthGuardService] CanLoad E', route);
            console.log('[AuthGuardService] forbiden B', route);
            this.router.navigate(['/forbiden']);
            return false;
        }
    }

    canActivate(): Observable<boolean> | boolean {
        const clientId = localStorage.getItem('client');
        console.log('[AuthGuardService] canActivate A');

        if (this.global.loggedUser()) {
            if (['forbiden'].indexOf(this.router.url) >= 0) {
                console.log('[AuthGuardService] canActivate B');            
                this.router.navigate(['/home']);
            }
            console.log('[AuthGuardService] canActivate C');
            return true;
        }
        else if ((this.auth.params) && (this.auth.params.token)) {
            console.log('[AuthGuardService] canActivate D');
            return true;
        }
        else {
            if (['/forbiden','/'].indexOf(this.router.url) >= 0) {
                console.log('[AuthGuardService] canActivate E');
                return true;
            }
            console.log('[AuthGuardService] canActivate F');
            return false;
        }
    }

    errorNotificate(msg: string): any {
        swal({
            title: 'Erro',
            text: msg,
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
        });
    }
}
