import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let changeReq;
    let clientId = localStorage.getItem('client');
    if (!clientId) clientId = localStorage.getItem('client');
    if (clientId) {
      changeReq = req.clone({
        params: req.params.append('client_id', clientId)
      });
    }
    else {
      changeReq = req.clone();
    }
    return next.handle(changeReq);
  }
}
