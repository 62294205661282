import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Global, AsapCrudModule } from 'asap-crud';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { AuthGuardService } from './services/auth-guard.service';

@NgModule({
    imports: [
        HttpClientModule,
        AsapCrudModule
    ],
    declarations: [
    ],
    exports: [
        HttpClientModule,
        AsapCrudModule,
    ],
    providers: [
        AuthService,
        AuthGuardService
    ],
    bootstrap: []
})
export class AuthModule {

    public static navigating = true;
    public static firebaseLoaded = false;
    public static config = null;
    public static hasNavbar = true;


    public profile;
    public permission;
    public leaderData;

    constructor(
        public global: Global,
        public auth: AuthService
    ) {
        console.log("[AuthModule]",new Date());
        localStorage.setItem("apiURL", environment.apiURL);
        localStorage.setItem("inEvents",'1');
        this.proceedLogon();
    }

    public proceedLogon() {
        let routeURL = location.href;
        console.log("[AuthModule] ProceedLogon",routeURL);
        this.auth.params = this.getQueryParams(routeURL);

        /** SE EXISTIR TOKEN FORÇA O LOGIN **/
        if (this.auth.params && this.auth.params.token) {
            localStorage.removeItem('client');
            localStorage.removeItem('token');
            localStorage.removeItem('loggedUser');
        }
        /****/

        if (this.global.loggedUser()) {
            this.auth.theme(this.global.loggedUser().client_id).subscribe((response) => {
                AuthModule.config = response.data.config;
                AuthModule.firebaseLoaded = true;
                console.log('[AuthModule] ',AuthModule.config);
                this.adjustConfig();
            });
        }
        else if ((!this.auth.params) || (!this.auth.params.token)) {
            if (routeURL != '/forbiden')  {
                //window.location.href = '/forbiden';
            }
            console.log('[AuthModule] Forbiden',this.auth.params);
            AuthModule.firebaseLoaded = true;
        }
        else {
            console.log('[AuthModule] Token',this.auth.params);
            this.auth.setSessionToken({token:this.auth.params.token}).then(
                (response) => {
                    localStorage.setItem('client',this.global.loggedUser().client_id); 
                    setTimeout(() => {
                        this.auth.theme(this.global.loggedUser().client_id).subscribe((response) => {
                            AuthModule.config = response.data.config;
                            AuthModule.firebaseLoaded = true;
                            console.log('[AuthModule] ',AuthModule.config);
                            this.adjustConfig();
                        });
                    }, 500);
                },
                (error) => {
                    console.log('[AuthModule] forbiden A');
                    AuthModule.firebaseLoaded = true;
                    if (routeURL != '/forbiden')  {
                        //window.location.href = '/forbiden';
                    }
                }
            );                   
        }      
    }

    public getQueryParams(route) {
        let uri = route.split("?");
        if (uri.length == 1) {
            return null;
        }
        else {
            let result: any = {}
            uri = uri[1];
            uri = uri.split("&");
            for (let params of uri) {
                let paramIdx = params.indexOf("=");
                let key = params.substring(0, paramIdx);
                let value = params.substring(paramIdx + 1);
                result[key] = decodeURIComponent(value);
            }
            return result;
        }
    }

    public adjustConfig() {
        const html = document.querySelector('html');

        html.style.setProperty('--bg-client', AuthModule.config.bgClient);

        html.style.setProperty('--main-color', AuthModule.config.mainColor);

        html.style.setProperty('--bg-navbar', AuthModule.config.bgNavbar);
        html.style.setProperty('--link-navbar', AuthModule.config.linkNavbar);

        html.style.setProperty('--bg-sidebar', AuthModule.config.bgSidebar);
        html.style.setProperty('--link-sidebar', AuthModule.config.linkSidebar);

        html.style.setProperty('--bg-footer', AuthModule.config.bgFooter);
        html.style.setProperty('--link-footer', AuthModule.config.linkFooter);

        html.style.setProperty('--bg-btn', AuthModule.config.bgBtn);
        html.style.setProperty('--text-btn', AuthModule.config.textBtn);

        html.style.setProperty('--bg-sidebar-viewer', AuthModule.config.bgSidebarViewer);
        html.style.setProperty('--link-sidebar-viewer', AuthModule.config.mainColor);
        html.style.setProperty('--bg-btn-viewer', AuthModule.config.bgBtnViewer);
        html.style.setProperty('--text-btn-viewer', AuthModule.config.textBtnViewer);
        html.style.setProperty('--text-btn-viewer-active', AuthModule.config.textBtnViewerActive);

        html.style.setProperty('--primary-color', AuthModule.config.primaryColor);
        html.style.setProperty('--primary-color-hover', AuthModule.config.primaryColorHover);

        html.style.setProperty('--app-logo', AuthModule.config.logoDark);

        let bg = AuthModule.config.bgBtn.split("#").join("");
        let cl = AuthModule.config.textBtn.split("#").join("");
        localStorage.setItem('imageURL', `https://ui-avatars.com/api/?background=${bg}&color=${cl}&size=400&bold=true&name=`)

    }

}
