import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { ForbidenComponent } from './auth-guard/forbiden.component';
import { LandpageComponent } from './landpage/landpage.component';
import { DashboardComponent } from './dashboard/dashboard.component';


const routes: Routes = [
    {
        path: 'forbiden',
        component: ForbidenComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'home',
        component: LandpageComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: '',
        component: DashboardComponent,
        loadChildren: () => import('./events/event-routing.module').then(m => m.EventRoutingModule)
    },
    {
        path: '',
        loadChildren: () => import('./events/jitsi/jitsi.module').then(m => m.JitsiModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{useHash:false})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
