import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { OwlModule } from 'ngx-owl-carousel';
import { AsapLimitToModule } from 'asap-limit-to';
import { AsapSkeletonModule } from 'asap-skeleton';
import { Ng2IziToastModule } from 'ng2-izitoast';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AsapLimitToModule,
    AsapSkeletonModule,
    OwlModule,
    Ng2IziToastModule
  ],
  declarations: [

  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OwlModule,
    Ng2IziToastModule
  ]
})

export class SharedModule { }
