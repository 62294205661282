import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from 'asap-crud';
import { AuthModule } from '../auth.module';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ]
})
export class DashboardComponent {

    public theme = AuthModule;

    constructor(
        public global: Global,
        public router: Router
    ) {
        this.router.navigate([],{ queryParams: { } });
    }

    public exit() {
        window.close();
    }


}
