import { Component } from '@angular/core';
import { Global } from 'asap-crud';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthModule } from './auth.module';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    public profile;
    public permission;
    public leaderData;

    public auth = AuthModule;

    constructor(
        public global: Global,
        public router: Router
    ) {

    }

    ngOnInit(): void {
        this.router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event)
        });

        if (environment.production) {
            window.console.log = function () { };
        }
    }

    public navigationInterceptor(event: RouterEvent): void {
        
        if (event instanceof NavigationStart) { 
            console.log("[navigationInterceptor] NavigationStart A",event);
            AuthModule.navigating = true;
            //this.blockUI.start('Carregando...');
            if (this.global.loggedUser()) { 
                if (event.url == '/') {
                    this.router.navigate(['/home']);
                }  
            }
            else {
                if (!event.url.includes('/forbiden')) {
                    this.router.navigate(['/forbiden']);
                }
            }
        }

        if (event instanceof NavigationEnd) {
            console.log("[navigationInterceptor] NavigationEnd");
            AuthModule.hasNavbar = !event.url.includes("video-call");
            AuthModule.navigating = false;
        }


        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            console.log("[navigationInterceptor] NavigationCancel");
            AuthModule.navigating = false;
            //this.blockUI.stop()
        }

        if (event instanceof NavigationError) {
            console.log("[navigationInterceptor] NavigationError");
            AuthModule.navigating = false;
            //this.blockUI.stop()
        }
    }


}
